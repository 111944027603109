import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

class ProductPreview extends Component {
  render() {
    const MAX_LENGTH = 100
    const MAX_LENGTH_Title = 80
    const { product } = this.props

    return (
      <article className="blog-listing" key={product.slug}>
        <div className="entry-meta-content">
          <div className="entry-media">
            <Img
              fluid={product.thumnail.fluid}
              backgroundColor={"#f4f8fb"}
              loading="eager"
              fadeIn={false}
            />
          </div>
          <h2 className="entry-title">
            <Link to={`/${product.slug}`}>
              {" "}
              {product.title > MAX_LENGTH
                ? product.name
                : product.name.substring(0, MAX_LENGTH_Title)}{" "}
            </Link>
          </h2>
          <div className="entry-content">
            {/* <p>{blog.blogShortDesc}</p>    */}

            {product.escription > MAX_LENGTH
              ? product.description.description
              : product.description.description.substring(0, MAX_LENGTH) +
                "..."}
          </div>
        </div>

        <div className="entry-content-bottom">
          <Link to={`/${product.slug}`} className="entry-read-more">
            <span />
            さらに読む
          </Link>
        </div>
      </article>
    )
  }
}

export default ProductPreview
