import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ProductPreview from "../components/ProductPreview"

export class Product extends Component {
  render() {
    const products = this.props.data.allContentfulProduct.edges

    return (
      <div>
        <Layout>
          <div className="container">
            <div className="col-md-8 offset-md-2">
              <div className="news-container">
                <ul className="article-list row">
                  {products.map(({ node }) => {
                    return (
                      <li key={node.slug} className="col-lg-6">
                        <ProductPreview product={node} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default Product

export const pageQuery = graphql`
  query productQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulProduct {
      edges {
        node {
          thumnail {
            file {
              url
            }
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          slug
          name
          description {
            description
          }
        }
      }
    }
  }
`
